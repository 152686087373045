import { render, staticRenderFns } from "./LeaveListing.vue?vue&type=template&id=769b6f95"
import script from "./LeaveListing.vue?vue&type=script&lang=js"
export * from "./LeaveListing.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports